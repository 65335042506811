import { AppDispatch, RootState } from '@/store';
import { updateScenarioSettings } from '@/store/storeSlice';
import { ScenarioSettings as TScenarioSettings } from '@/store/types';
import {
  HandleError,
  Input,
  Label,
  Textarea,
  useDebounceCallback,
} from '@data-products-and-ai/react-components';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SetParameters } from './components/SetParameters';
import { handleInputChange } from './helpers';
const initialForm = {
  title: '',
  description: '',
  parameters: [
    {
      Fixed_WaitOnBatchTimeAsFixedHours: false,
      Fixed_WaitOnBatchTimeAsFixedHours_value: 8,
      Variable_WaitOnBatchTimeAsProcessTimePercentage: true,
      Variable_WaitOnBatchTimeAsProcessTimePercentage_value: 80,
      DropDown_JobsPrioritisation: 3,
      DropDown_UseOfAdditionalEquipment: true,
      DropDown_UseEachPOStartDate: true,
      DropDown_FileSelection: 1,
      Batch_Fill_Grade_Percentage: 95,
      Dropdown_Use_Storage: false,
      Dropdown_Use_Personnel_Module: false,
      Batch_Grouping_Option: false,
      Dropdown_Worker_Pooling: false,
      Dropdown_Scrap_Trigger: false,
      Dropdown_Scrap_Delete: false,
    },
  ],
};

const ScenarioSettings = () => {
  const dispatch: AppDispatch = useDispatch();

  const selectedScenario = useSelector(
    (state: RootState) => state.store.Simulation,
  ).scenarios.find((item) => item.is_selected)?.settings;

  const [form, setForm] = useState<TScenarioSettings>(
    selectedScenario || initialForm,
  );

  const handleInputChangeDebounced = useDebounceCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      handleInputChange(event, setForm);
    },
    1000,
  );

  useEffect(() => {
    setForm(selectedScenario || initialForm);
  }, [selectedScenario]);

  useEffect(() => {
    dispatch(updateScenarioSettings({ settings: form }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  if (!form) return <></>;

  return (
    <>
      {/*  <pre>{JSON.stringify(form, null, 2)}</pre> */}

      <div style={{ width: '80%', margin: '0 auto', paddingTop: 20 }}>
        <form>
          <Label tag="textdefault">Title</Label>
          <HandleError mandatory mandatoryError="Title is mandatory">
            <Input
              maxLength={60}
              placeholder="Write a title for your scenario"
              id="title"
              defaultValue={form.title}
              onChange={(event) => handleInputChangeDebounced(event)}
            ></Input>
          </HandleError>
          <Label tag="textdefault" marginTop={30}>
            Description
          </Label>
          <Textarea
            id="description"
            defaultValue={form.description}
            onChange={(event) => handleInputChangeDebounced(event)}
          ></Textarea>

          <SetParameters form={form} setForm={setForm} />
        </form>
      </div>
    </>
  );
};

export default ScenarioSettings;
