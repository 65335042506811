import { useState } from 'react';

const useLaunchWorkflow = (url: string, accessToken: string) => {
  const [workflowId, setWorkflowId] = useState(null);

  const launchWorkflow = async (fileId: string) => {
    try {
      const response = await fetch(`${url}/webapi/user/v2/workflows/66ffa55e580d93e89b010457/jobs?includeMessages=true`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          credentialId: '637a2a09360a37bf2e8198ff',
          questions: [{ name: 'File Browse (1190)', value: fileId }],
          priority: 'High',
        }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setWorkflowId(data.id);
      return data.id;
    } catch (error) {
      console.error('Failed to launch workflow',error);
    }
  };

  return { launchWorkflow, workflowId };
};

export default useLaunchWorkflow;