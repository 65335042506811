import { useState, useEffect } from 'react';

const useCheckJobStatus = (url:string, accessToken:string) => {
  const [jobStatus, setJobStatus] = useState(null);

  const checkJobStatus = async (jobId: string) => {
    try {
      const response = await fetch(`${url}/webapi/v3/jobs/${jobId}?includeMessages=true`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setJobStatus(data);
      return data;
    } catch (error) {
      console.error('Failed to check job status');
    }
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (jobStatus && jobStatus.status !== 'Completed') {
      interval = setInterval(() => {
        checkJobStatus(jobStatus.id);
      }, 50000);
    }
    return () => clearInterval(interval);
  }, [jobStatus]);

  return { checkJobStatus, jobStatus };
};

export default useCheckJobStatus;